import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Stack, Typography } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';

interface CustomFieldCheckboxProps {
    customField: ICustomField;
    onChange: Function;
}

export default function CustomFieldCheckbox({ customField, onChange }: CustomFieldCheckboxProps) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(false);
        onChange(customField, false);
    }, []);

    let handleChange = () => {
        onChange(customField, !isChecked);
        setIsChecked(!isChecked);
    };

    return (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <FormControlLabel
                key={customField.id}
                label={customField.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? customField.name['sv']}
                control={
                    <Checkbox
                    checked={isChecked}
                    onChange={handleChange}
                />
                } />
        </Stack>
    );
}
