import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';

interface CustomFieldTextProps {
    customField: ICustomField;
    onChange: Function;
}

export default function CustomFieldText({ customField, onChange }: CustomFieldTextProps) {
    const [val, setVal] = useState('');

    useEffect(() => {
      setVal('');
      onChange(customField, val);
    }, []);

    let handleChange = (value) => {
      setVal(value);
      onChange(customField, value);
    };

    return (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <FormControl
            variant="outlined"
            key={customField.id}
            fullWidth
            margin="dense">
            <OutlinedInput
                size="small"
                placeholder={customField.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? customField.name['sv']}
                sx={{ borderColor: '#004E31', borderRadius: 1 }}
                onChange={(e) => handleChange(e.target.value)}
            />
          </FormControl>
        </Stack>
    );
}
