import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';

interface CustomFieldDropdownProps {
    customField: ICustomField;
    onChange: Function;
}

export default function CustomFieldDropdown({ customField, onChange }: CustomFieldDropdownProps) {
    const [val, setVal] = useState<string>('');

    useEffect(() => {
        setVal('');
        onChange(customField, '');
    }, []);

    const handleChange = (event: SelectChangeEvent) => {
        setVal(event.target.value);
        onChange(customField, event.target.value);
    };

    return (
        <FormControl variant="outlined" size="small">
            <InputLabel id={customField.id}>{customField.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? customField.name['sv']}</InputLabel>
            <Select
                labelId={customField.id}
                id={customField.id}
                label={customField.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? customField.name['sv']}
                value={val}
                onChange={handleChange}
            >
                {customField.options?.map((o) => <MenuItem value={o.value}>{o.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? o.name['sv']}</MenuItem>)}
            </Select>
        </FormControl>
    );
}
